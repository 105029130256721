<template>
  <section>
    <div class="header pb-5">
      <app-navigation />

      <br />

      <div class="header-title">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Experience</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="header-breadcrumb">
              <router-link to="/"><i class="fa fa-fw fa-home"></i></router-link> / Experience
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main pt-9">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="main-timeline">
              <div class="timeline">
                <div class="timeline-content">
                  <span class="timeline-year">Sept 2017<br class="d-none d-lg-block d-xl-block d-xxl-block" /><span class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"> - </span>Current</span>
                  <h3 class="title">AWS DevOps Engineer</h3>
                  <h5 class="company">Pagely, Inc</h5>
                  <div class="description">
                    <ul>
                      <li>Provision new AWS EC2 instances for customers utilizing the Pagely WordPress SaaS/PaaS Stack</li>
                      <li>Contribute to the companies next generation micro-service APIs, WordPress management plugins, performance monitoring platform, and systems automation tools</li>
                      <li>Investigate and mitigate customer server issues</li>
                      <li>Investigate and assist customers with deploying and enhancing WordPress-based websites</li>
                      <li>Contribute to automation tools and scripts including Ansible and Jenkins</li>
                      <li>Perform scheduled maintenance and other tasks on customer and company infrastructure</li>
                      <li>Participate in daily on-call rotation</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <span class="timeline-year">Sept 2020<br class="d-none d-lg-block d-xl-block d-xxl-block" /><span class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"> - </span>Current</span>
                  <h3 class="title">Principal Engineer (Contractor)</h3>
                  <h5 class="company">FarmFlight, LLC</h5>
                  <div class="description">
                    <ul>
                      <li>Architect and develop a web-based platform for highly detailed drone imagery and analytics for agriculture (VueJS with Bootstrap and Buefy / NodeJS + Express API)</li>
                      <li>Architect dev, demo and prod environments using AWS S3, Cloudfront, Route53, and EC2</li>
                      <li>Design MySQL-based database schema</li>
                      <li>Automate app builds and deployments to AWS S3, Cloudfront and EC2 using Ansible and Jenkins</li>
                      <li>Develop integration with AWS SDK for S3 and Wasabi file storage</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <span class="timeline-year">Mar 2014<br class="d-none d-lg-block d-xl-block d-xxl-block" /><span class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"> - </span>Current</span>
                  <h3 class="title">Lead DevOps Engineer (Contractor)</h3>
                  <h5 class="company">VPSFX, LLC</h5>
                  <div class="description">
                    <ul>
                      <li>Rack, cable and configure physical servers for VPS hosting environment</li>
                      <li>Install, configure and maintain VPS hosting environment based on OpenVZ and SolusVM</li>
                      <li>Work help desk tickets and assist customers with issues</li>
                      <li>Automate server deployments, updates and more using Ansible and Jenkins</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <span class="timeline-year">Jan 2014<br class="d-none d-lg-block d-xl-block d-xxl-block" /><span class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"> - </span>Jan 2017</span>
                  <h3 class="title">Developer / DevOps Engineer</h3>
                  <h5 class="company">Alert GPS Holdings, Inc</h5>
                  <div class="description">
                    <ul>
                      <li>Lead AWS DevOps Engineer</li>
                      <li>Lead developer on multiple projects including Law Enforcement Alerting Portal (LEAP) and amberalert.com</li>
                      <li>Lead development of the backend management application for the Enterprise Tracking Portal</li>
                      <li>Lead developer for an iOS app using GPS and a custom BLE device</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="timeline">
                <div class="timeline-content">
                  <span class="timeline-year">Sept 2010<br class="d-none d-lg-block d-xl-block d-xxl-block" /><span class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none"> - </span>Jan 2014</span>
                  <h3 class="title">Linux Engineer / PHP Developer</h3>
                  <h5 class="company">Iveda Solutions, Inc</h5>
                  <div class="description">
                    <ul>
                      <li>Reverse Engineer and design environment for hosting Axis AVHS for VSaaS</li>
                      <li>Lead developer for IvedaXpress Video Hosting Management Platform (based on Axis AVHS)</li>
                      <li>Design and develop custom in-house tools using the LAMP stack for monitoring applications and performing calculations</li>
                      <li>Created baselines and made projections for future hardware purchases and network growth</li>
                      <li>Automated server and network monitoring tasks using Solarwinds, Splunk, and other tools</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <strong>All experience prior to 2010 can be provided upon request. Most of the experience includes data center work as well as PHP development and Linux administration.</strong>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "My Experience | DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>